import { Vue, Component } from "vue-property-decorator";
import { WELCOME_SCREEN_BODY_CLASSNAME } from "@config/base";
import { telegramGroupLink } from "@config/social";
import { depositWithoutCommission } from "@config/welcome";
import { formatMoney } from "@helpers";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";

@Component
export default class WelcomeScreen extends Vue {
  @profileModule.Getter
  private readonly profileFreeCardsTotalPromocode!: ProfileGetters["profileFreeCardsTotalPromocode"];

  private get tileItems() {
    return [
      {
        code: "refill",
        imageUrl: require("./img/refill.png"),
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.welcome.refill.text",
          formatMoney({
            value: depositWithoutCommission.maxAmount,
            currency: depositWithoutCommission.currency,
          })
        ),
        button: {
          text: this.$vuetify.lang.t("$vuetify.dashboard.to_refill"),
          props: {
            color: "primary",
            to: {
              name: "refill",
            },
          },
        },
      },
      {
        code: "issue-card",
        imageUrl: require("./img/issue-card.png"),
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.welcome.issueCard.text",
          this.profileFreeCardsTotalPromocode.bonus
        ),
        button: {
          text: this.$vuetify.lang.t("$vuetify.fields.card_issue"),
          props: {
            color: "success",
          },
          listeners: {
            click: () => {
              this.$emit("issue:card");
            },
          },
        },
      },
      {
        code: "support",
        imageUrl: require("./img/support.png"),
        text: this.$vuetify.lang.t("$vuetify.dashboard.welcome.support.text"),
        button: {
          text: this.$vuetify.lang.t("$vuetify.subscribe"),
          props: {
            href: telegramGroupLink,
            target: "_blank",
            color: "primary",
          },
        },
      },
    ];
  }

  private mounted() {
    document.body.classList.add(WELCOME_SCREEN_BODY_CLASSNAME);
  }

  private beforeDestroy() {
    document.body.classList.remove(WELCOME_SCREEN_BODY_CLASSNAME);
  }
}
